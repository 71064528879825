import React, { useState } from "react";
import CustomHeader from "../../components/header";
import { Select, Spin, Tabs } from "antd";
import { useQuery } from "react-query";
import { PubmedData, PubmedDataItems } from "../../services/dataService";

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

let COLOR_FACTORY = [
  // 001219-005f73-0a9396-94d2bd-e9d8a6-ee9b00-ca6702-bb3e03-ae2012-9b2226
  "#004966",
  "#005f73",
  "#0a9396",
  "#94d2bd",

  "#D2B04B",
  "#ee9b00",
  "#ca6702",
  "#bb3e03",
];

const KeyMapping = {
  study_type: "Study Type",
  ta_for_drug: "Therapeutic Association for Query Drug",
  ta: "Therapeutic Association",
};
const StatsBarChart = ({ data }) => {
  const [selectedKey, setSelectedKey] = useState("study_type");
  let available_keys = ["study_type", "ta_for_drug", "ta"];
  //   let key = "study_type";

  let uniqueKeys = [];
  let process_data = data.map((item) => {
    let temp = {
      name: item["tissue"],
    };
    item[selectedKey].forEach((element) => {
      temp[element["key"]] = element["doc_count"];

      // Add unique keys
      if (!uniqueKeys.includes(element["key"])) {
        uniqueKeys.push(element["key"]);
      }
    });
    return temp;
  });

  console.log(process_data, uniqueKeys);
  return (
    <div className="h-[200vh] w-screen">
      <Select
        defaultValue="study_type"
        style={{ width: 300 }}
        onChange={(value) => setSelectedKey(value)}
      >
        {available_keys.map((key) => {
          return <Select.Option value={key}>{KeyMapping[key]}</Select.Option>;
        })}
      </Select>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={process_data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          layout="vertical"
          barSize={24}
          onClick={(e) => console.log(e)}
        >
          <Legend verticalAlign="top" />

          {/* <CartesianGrid strokeDasharray="3 3" /> */}
          <XAxis type="number" hide />
          <YAxis dataKey="name" type="category" axisLine={false} width={150} />
          <Tooltip />
          {uniqueKeys.map((key) => {
            return (
              <Bar
                dataKey={key}
                stackId="a"
                fill={COLOR_FACTORY[uniqueKeys.indexOf(key)]}
              />
            );
          })}
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const Scoring = () => {
  const [selectedTissue, setSelectedTissue] = useState("Prostate");
  const scoringData = useQuery("scoringData", () => {
    return PubmedDataItems.scoringData();
  });

  let overallScores = scoringData.data?.score;
  let breakdown = scoringData.data?.breakdown;
  let processedData = overallScores
    ?.filter((item) => {
      if (selectedTissue === "All") {
        return true;
      } else {
        return item.tissuename === selectedTissue;
      }
    })
    .map((item) => {
      item.name = item.drug;
      return item;
    });

  let studyTypeData = [];
  let uniqueStudyTypes = [];
  let yearWiseData = [];
  let uniqueYears = [];
  let taData = [];
  let uniqueTAs = [];
  breakdown
    ?.filter((item) => {
      return item.tissuename === selectedTissue;
    })
    .forEach((item) => {
      item.name = item.drug;

      studyTypeData.push({
        name: item.name,
        ...item.study_type,
      });
      taData.push({
        name: item.name,
        ...item.ta,
      });

      yearWiseData.push({
        name: item.name,
        ...item.publication_year,
      });

      Object.keys(item.study_type).forEach((element) => {
        if (!uniqueStudyTypes.includes(element)) {
          uniqueStudyTypes.push(element);
        }
      });

      Object.keys(item.ta).forEach((element) => {
        if (!uniqueTAs.includes(element)) {
          uniqueTAs.push(element);
        }
      });

      Object.keys(item.publication_year || {}).forEach((element) => {
        if (!uniqueYears.includes(element)) {
          uniqueYears.push(element);
        }
      });
    });

  let uniqueTissue = [];

  overallScores?.forEach((item) => {
    if (!uniqueTissue.includes(item.tissuename)) {
      uniqueTissue.push(item.tissuename);
    }
  });

  return (
    <div className="bg-gray-100 ">
      <Select
        defaultValue="Prostate"
        style={{ width: 300 }}
        onChange={(value) => setSelectedTissue(value)}
        showSearch
      >
        {uniqueTissue?.map((item) => {
          return <Select.Option value={item}>{item}</Select.Option>;
        })}
      </Select>
      <div className="flex grid grid-cols-2 p-4 gap-8">
        <div className="h-[45vh] overflow-y-auto basis-1/2 bg-white rounded-md">
          <div className="flex justify-center items-center mt-2">
            <h3 className="text-base font-semibold">Overall Score</h3>
          </div>
          {scoringData.isLoading ? (
            <div className="h-full  flex items-center justify-center">
              <Spin />
            </div>
          ) : null}
          {!scoringData.isLoading && (
            <div
              style={{
                height: processedData?.length * 50 + "px",
              }}
              className="p-4"
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={processedData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  layout="vertical"
                  barSize={24}
                  onClick={(e) => console.log(e)}
                >
                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis type="number" orientation="top" />
                  <YAxis
                    dataKey="name"
                    type="category"
                    axisLine={false}
                    width={100}
                  />
                  <Tooltip />
                  {/* {uniqueKeys.map((key) => {
            return (
              <Bar
                dataKey={key}
                stackId="a"
                fill={COLOR_FACTORY[uniqueKeys.indexOf(key)]}
              />
            );
          })} */}
                  <Bar dataKey="total_score" fill="#0a9396" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
        <div className="h-[45vh] overflow-y-auto basis-1/2  bg-white rounded-md">
          <div className="flex justify-center items-center mt-2">
            <h3 className="text-base font-semibold">Study Types</h3>
          </div>
          {scoringData.isLoading ? (
            <div className="h-full  flex items-center justify-center">
              <Spin />
            </div>
          ) : null}
          {!scoringData.isLoading && (
            <div
              style={{
                height: processedData?.length * 50 + "px",
              }}
              className="p-4"
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={studyTypeData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  layout="vertical"
                  barSize={24}
                  onClick={(e) => console.log(e)}
                >
                  <Legend
                    verticalAlign="top"
                    style={{
                      display: "fixed",
                    }}
                  />

                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis type="number" orientation="top" />
                  <YAxis
                    dataKey="name"
                    type="category"
                    axisLine={false}
                    width={100}
                  />
                  <Tooltip />
                  {uniqueStudyTypes.map((key) => {
                    return (
                      <Bar
                        dataKey={key}
                        stackId="a"
                        fill={COLOR_FACTORY[uniqueStudyTypes.indexOf(key)]}
                      />
                    );
                  })}
                  {/* <Bar dataKey="total_score" fill="#0a9396" /> */}
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
        <div className="h-[45vh] overflow-y-auto basis-1/2  bg-white rounded-md">
          <div className="flex justify-center items-center mt-2">
            <h3 className="text-base font-semibold">Therapeutic Association</h3>
          </div>
          {scoringData.isLoading ? (
            <div className="h-full w-full flex items-center justify-center">
              <Spin />
            </div>
          ) : null}
          {!scoringData.isLoading && (
            <div
              style={{
                height: processedData?.length * 50 + "px",
              }}
              className="p-4"
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={taData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  layout="vertical"
                  barSize={24}
                  onClick={(e) => console.log(e)}
                >
                  <Legend
                    verticalAlign="top"
                    style={{
                      display: "fixed",
                    }}
                  />

                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis type="number" orientation="top" />
                  <YAxis
                    dataKey="name"
                    type="category"
                    axisLine={false}
                    width={100}
                  />
                  <Tooltip />
                  {uniqueTAs.map((key) => {
                    return (
                      <Bar
                        dataKey={key}
                        stackId="a"
                        fill={COLOR_FACTORY[uniqueTAs.indexOf(key)]}
                      />
                    );
                  })}
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
        <div className="h-[45vh] overflow-y-auto basis-1/2  bg-white rounded-md">
          <div className="flex justify-center items-center mt-2">
            <h3 className="text-base font-semibold">Publication Year</h3>
          </div>
          {scoringData.isLoading ? (
            <div className="h-full w-full flex items-center justify-center">
              <Spin />
            </div>
          ) : null}
          {!scoringData.isLoading && (
            <div
              style={{
                height: processedData?.length * 50 + "px",
              }}
              className="p-4"
            >
              <ResponsiveContainer width="100%" height="100%">
                <BarChart
                  width={500}
                  height={300}
                  data={yearWiseData}
                  margin={{
                    top: 20,
                    right: 30,
                    left: 20,
                    bottom: 5,
                  }}
                  layout="vertical"
                  barSize={24}
                  onClick={(e) => console.log(e)}
                >
                  {/* <Legend
                    verticalAlign="top"
                    style={{
                      display: "fixed",
                    }}
                  /> */}

                  {/* <CartesianGrid strokeDasharray="3 3" /> */}
                  <XAxis type="number" orientation="top" />
                  <YAxis
                    dataKey="name"
                    type="category"
                    axisLine={false}
                    width={100}
                  />
                  <Tooltip />
                  {uniqueYears.map((key) => {
                    return (
                      <Bar
                        dataKey={key}
                        stackId="a"
                        fill={
                            // COLOR_FACTORY[uniqueTAs.indexOf(key)]

                            "#" + Math.floor(Math.random() * 16777215).toString(16)
                        }
                      />
                    );
                  })}
                </BarChart>
              </ResponsiveContainer>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
const ScoringView = () => {
  const stats = useQuery("tissueStats", () => {
    return PubmedDataItems.tissueStats();
  });

  return (
    <div>
      <CustomHeader />
      <div className="mx-4">
        <Tabs defaultActiveKey="1">
          <Tabs.TabPane
            tab="Tissue Stats"
            key="1"
            className="h-[90vh] overflow-y-scroll"
          >
            {stats.data ? <StatsBarChart data={stats.data} /> : null}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Scoring" key="2">
            <Scoring />
          </Tabs.TabPane>
          {/* <Tabs.TabPane tab="Tab 3" key="3">
            Content of Tab Pane 3
          </Tabs.TabPane> */}
        </Tabs>
      </div>
    </div>
  );
};

export default ScoringView;
