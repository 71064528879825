import React from "react";

import {
  Row,
  Col,
  Button,
  Card,
  Tag,
  Typography,
  Input,
  List,
  Divider,
  Space,
  Popover,
  Tabs,
  message,
  Modal,
  Radio,
  Checkbox,
} from "antd";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { MoreOutlined } from "@ant-design/icons";
import axios from "axios";
import remarkGfm from "remark-gfm";
import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { useQuery, useMutation, useQueryClient } from "react-query";

import createPersistedState from "use-persisted-state";
import CustomHeader from "../../components/header";
import {
  BookmarkService,
  HelperServices,
  PubmedData,
  PubmedDataItems,
} from "../../services/dataService";
import DetailForm from "../pubmedData/detailForm";
import FilterView from "../pubmedData/filterViewV2";
import * as _ from "lodash";
import TableView from "../pubmedData/tableView";
import ConfigEditView from "../pubmedData/configEditView";
import ScoringView from "../pubmedData/scoring";
import UploadPDF from "../pubmedData/importPDF";
import PDFDocView from "../pubmedData/DocView";
import { useAuth } from "../../authContext";
import PubDetailViewV2 from "../pubmedData/detailViewV2";
import PublicationCard from "./card";
import SelectedArticlesView from "./selectedItems";
import ImportArticleModal from "./articleImport";


// import { Header } from "antd/es/layout/layout";
const labelAppState = createPersistedState("labelAppState");
const useSelectedArticleState = createPersistedState("selectedArticleState");



function PubmedDataHomeV2(props) {
  const [tab, setTab] = useState("1");
  const [importArticle, setImportArticle] = useState(false);

  const match = useMatch("/dataset/:id");
  const [selectedArticles, setSelectedArticles] = useSelectedArticleState([]);

  const queryClient = useQueryClient();

  const [state, setState] = useState({
    tableView: false,
    splitView: true,
    loading: true,
    editConfig: false,
    page: 1,
    search: [],
  });
  const ref = useRef(null);

  useEffect(() => {
    // ref.current?.scrollIntoView({
    //   block: "start",
    //   inline: "nearest",
    //   behavior: "smooth",
    // });
  }, [state.selected]);

  // const labelTaskDetail = useQuery(
  //   ["dataset_detail", match.params.id],
  //   () => PubmedData.get(match.params.id),
  //   { enabled: match.params.id != undefined }
  // );

  const datasetItems = useQuery(
    ["dataset_items", state.searchNFilter, state.page, state.size],
    () => {
      return PubmedDataItems.search({
        page: state.page,
        size: state.size,
        search: state.searchNFilter?.search,
        pmids: state.searchNFilter?.pmids,
        filters: state.searchNFilter?.otherFilters
          ? JSON.stringify(state.searchNFilter?.otherFilters)
          : undefined,
        
          parsedQuery: JSON.stringify(state.searchNFilter?.parsedQuery),
          // data_id: match.params.id,
      });
      // }
    },
    {
      staleTime: Infinity,
    }
  );

  const exportResults = useMutation(() => {
    return PubmedDataItems.exportToExcel({
      search: state.searchNFilter?.search,
      filters: state.searchNFilter?.otherFilters
        ? JSON.stringify(state.searchNFilter?.otherFilters)
        : undefined,
        parsedQuery: JSON.stringify(state.searchNFilter?.parsedQuery),
      
    });
  });

  const summaryMutation = useMutation(async () => {
    const searchItems = datasetItems;

    let pubmedIds = searchItems.data?.results.map((item) => item.pubmed_id);
    return PubmedDataItems.summarize({
      pubmed_ids: pubmedIds,
      query: state.searchNFilter?.search,
      filters: state.searchNFilter?.otherFilters
        ? JSON.stringify(state.searchNFilter?.otherFilters)
        : undefined,
        parsedQuery: JSON.stringify(state.searchNFilter?.parsedQuery),
    });
  });

  const searchItems = datasetItems;

  const nextLabelItem = useMutation(() =>
    PubmedDataItems.get_next(
      state.selected.id,
      {
        acquire_lock: true,
        is_labelled: false,
        task_id: state.selected.task_id,
      },
      {
        onSuccess: (data) => {
          props.onNext(data);
        },
      }
    )
  );

  const loadAndMoveToNext = async () => {
    nextLabelItem.mutateAsync().then((data) => {
      setState((prev) => ({ ...prev, selected: data }));
    });

    //  props.onNext(nextLabelItem.data);
  };

  const viewOptions = [
    { label: "List", value: "listView" },
    { label: "Single", value: "singleView" },
  ];

  const debounceUpdate = _.debounce((value) => {
    setState((prev) => ({ ...prev, searchNFilter: value }));
  }, 500);

  return (
    <div>
      <ImportArticleModal visible={importArticle} onCancel={() => setImportArticle(false)} />
      <CustomHeader />
      {/* <ScoringView
        filters={state.searchNFilter?.filters}
        visible={state.scoring}
        onCancel={() => {
          setState((prev) => ({ ...prev, scoring: false }));
        }}
      /> */}
      <ConfigEditView
        open={state.editConfig}
        onClose={() => {
          setState((prev) => ({ ...prev, editConfig: false }));
        }}
      />

      
      <div style={{ margin: "0px 2rem" }}>
        <Row>
          <Row style={{ width: "100%", margin: "0.5rem 0rem" }} align="middle">
            <FilterView
              onChange={debounceUpdate}
              onDisplayChange={(value) => {
                setState((prev) => ({
                  ...prev,
                  tableView: value === "table",
                  splitView: value === "split",
                }));
              }}
              currentDisplay={state.tableView ? "table" : state.splitView ? "split" : "list"}
            />
            <div style={{ marginLeft: "auto" }}>
              <Popover
                trigger="click"
                content={
                  <Space
                    direction="vertical"
                    split={
                      <Divider
                        style={{
                          margin: 0,
                        }}
                      />
                    }
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyItems: "center",
                      justifyContent: "center",
                      alignItems: "start",
                    }}
                  >
                    <Button
                      style={{
                        width: "100%",
                      }}
                      type="link"
                      loading={exportResults.isLoading}
                      onClick={() => {
                        exportResults.mutate();
                      }}
                    >
                      <i class="fa-solid fa-download mr-2"></i>
                      Export Search Results
                    </Button>
                
                    
                    <Button 
                      style={{
                        width: "100%",
                      }}
                      type="link"
                      onClick={() => {
                        setImportArticle(true);
                      }
                      }
                    >
                      {/* <i class="fa-solid fa-file-import mr-2"></i> */}
                      <i class="fa-solid fa-upload mr-2"></i>
                      Import Article
                    </Button>
                    {/* <Radio.Group
                      defaultValue="list"
                      buttonStyle="solid"
                      style={{}}
                      onChange={(e) => {
                        setState((prev) => ({
                          ...prev,
                          tableView: e.target.value === "table",
                          splitView: e.target.value === "split",
                        }));
                      }}
                    >
                      <Radio.Button value="split">
                        <i class="fa-solid fa-table-columns"></i>
                      </Radio.Button>
                      <Radio.Button value="list">
                        <i class="fa-solid fa-list"></i>
                      </Radio.Button>
                      <Radio.Button value="table">
                        <i class="fa-solid fa-table"></i>
                      </Radio.Button>
                    </Radio.Group> */}
                  </Space>
                }
              >
                <Button variant="outlined" color="primary">
                  {/* <MoreOutlined /> */}
                  <i class="fa-solid fa-ellipsis-vertical"></i>
                </Button>
              </Popover>
            </div>
          </Row>
          <Row style={{ width: "100%", marginTop: "0.5rem" }}>
            <br />
            <Tag color="grey" style={{}}>
              {searchItems.data?.total} Results
            </Tag>

            {searchItems.data?.total > 0 && (
            
              <SelectedArticlesView
              selectedArticles={selectedArticles}
              onRemove={(item) => {
                setSelectedArticles(selectedArticles.filter((article) => article.id !== item.id));
              }}
              removeAll={() => {
                setSelectedArticles([]);
              }
              }
              
            />
            )}

            <div>
              {summaryMutation.data && (
                <div>
                  <Typography.Title level={4}>Summary</Typography.Title>
                  <Typography.Text>
                    {summaryMutation.data?.summary}
                  </Typography.Text>
                </div>
              )}
            </div>
          </Row>
          {state.splitView ? (
            <Row style={{ width: "100%" }}>
              <Col
                span={8}
                style={{
                  width: "100%",
                  height: "90vh",
                  overflowY: "scroll",
                  // padding: "0px 20px",
                  padding: "0px 10px",
                }}
              >
                <List
                  pagination={{
                    pageSizeOptions: ["10", "50", "500", "1000"],
                    showSizeChanger: true,

                    onChange: (page, pageSize) => {
                      setState((prevState) => ({
                        ...prevState,
                        page: page,
                        size: pageSize,
                      }));
                    },
                    total: searchItems.data ? searchItems.data.total : 0,
                    current: state.page,
                    pageSize: state.size || 10,
                  }}
                  grid={{
                    gutter: 2,
                    xs: 1,
                    sm: 1,
                    md: 1,
                    lg: 1,
                    xl: 1,
                    xxl: 1,
                  }}
                  dataSource={searchItems.data ? searchItems.data.results : []}
                  // dataSource={labelItems.data}
                  loading={searchItems.isLoading}
                  renderItem={(item, idx) => (
                    <List.Item key={idx}>
                      <div key={item?.pubmed_id}>
                        <PublicationCard
                          item={item}
                          onClick={(item) => {
                            setState((prev) => ({ ...prev, selected: item }));
                          }}
                          onTabChange={(key) => {
                            setTab(key);
                          }}
                          selectedTab={tab}
                          showDetailView={false}
                          isSelected={state.selected?.id === item.id}
                          isSelectable={true}
                          // toggleSelect={(item) => {
                          //   if (selectedArticles.includes(item)) {
                          //     setSelectedArticles(selectedArticles.filter((article) => article.id !== item.id));
                          //   } else {
                          //     setSelectedArticles([...selectedArticles, item]);
                          //   }
                          // }}

                          bulkSelect={{
                            isSelected:
                              selectedArticles.filter(
                                (article) => article.id === item.id
                              ).length > 0,
                            toggle: (item) => {
                              if (
                                selectedArticles.filter(
                                  (article) => article.id === item.id
                                ).length > 0
                              ) {
                                setSelectedArticles(
                                  selectedArticles.filter(
                                    (article) => article.id !== item.id
                                  )
                                );
                              } else {
                                setSelectedArticles([
                                  ...selectedArticles,
                                  item,
                                ]);
                              }
                            },
                          }}
                        />
                      </div>
                    </List.Item>
                  )}
                />
              </Col>
              <Col
                span={16}
                style={{
                  width: "100%",
                  height: "90vh",
                  overflowY: "scroll",
                  // padding: "0px 20px",
                }}
              >
                {state.selected && (
                  <div style={{ margin: "1.5rem 1.5rem" }}>
                    <div ref={ref}></div>
                    <PubDetailViewV2 selected={state.selected} />
                  </div>
                )}
              </Col>
            </Row>
          ) : state.tableView ? (
            <Row style={{ width: "100%" }}>
              <TableView
                data={searchItems.data?.results}
                onPageChange={(page, pageSize) => {
                  setState((prevState) => ({
                    ...prevState,
                    page: page,
                    size: pageSize,
                  }));
                }}
                total={searchItems.data?.total?.value}
              />
            </Row>
          ) : (
            <Row style={{ width: "100%", padding: "0px 10px" }}>
              <List
                pagination={{
                  pageSizeOptions: ["10", "50", "500", "1000"],
                  showSizeChanger: true,
                  onChange: (page, pageSize) => {
                    setState((prevState) => ({
                      ...prevState,
                      page: page,
                      size: pageSize,
                    }));
                  },
                  total: searchItems.data ? searchItems.data.total.value : 0,
                  current: state.page,
                  pageSize: state.size || 10,
                }}
                grid={{
                  gutter: 2,
                  xs: 1,
                  sm: 1,
                  md: 1,
                  lg: 1,
                  xl: 1,
                  xxl: 1,
                }}
                dataSource={searchItems.data ? searchItems.data.results : []}
                // dataSource={labelItems.data}
                loading={searchItems.isLoading}
                renderItem={(item, idx) => (
                  <List.Item key={idx}>
                    <div key={item?.pubmed_id}>
                      <PublicationCard
                        item={item}
                        onClick={(item) => {
                          setState((prev) => ({ ...prev, selected: item }));
                        }}
                        onTabChange={(key) => {
                          setTab(key);
                        }}
                        selectedTab={tab}
                        showDetailView={true}
                      />
                    </div>
                  </List.Item>
                )}
              />
            </Row>
          )}
        </Row>
      </div>
      {/* </header> */}
    </div>
  );
}

export { PublicationCard };
export default PubmedDataHomeV2;
