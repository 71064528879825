import React, { useEffect, useState } from "react";
import {
  Select,
  Row,
  Space,
  Input,
  Button,
  Col,
  Radio,
  AutoComplete,
  Badge,
  Popover,
  Typography,
  Collapse,
} from "antd";
import {
  MinusCircleOutlined,
  TableOutlined,
  UnorderedListOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { PubmedDataItems } from "../../services/dataService";
import { useQuery } from "react-query";
import createPersistedState from "use-persisted-state";
import parseSearch from "../../components/searchParser";
import parsePubMedQuery from "../../components/searchParser";

const useFilterState = createPersistedState("filterState");

const AdvancedFilters = ({ onChange, value }) => {
  const [state, setState] = useState({
    ...value,
  });
  const filterSuggest = useQuery(
    ["filterSuggest"],
    () =>
      PubmedDataItems.filter_suggest_all({
        //   field_name: filter.filter,
        //   filters: allFilters,
      }),
    {}
  );
  return (
    <div
      style={{
        padding: "0.5rem",
        maxHeight: "500px",
        overflowY: "auto",
      }}
    >
      <Row>
        <Typography.Text>Advanced Filters</Typography.Text>
      </Row>

      <Row
        gutter={[16, 16]}
        style={{
          margin: "0.5rem 0rem",
        }}
      >
        <Col span={24}>
          <Typography.Text type="secondary">PMIDs</Typography.Text>
          <Input.TextArea
            placeholder="Enter PMIDs"
            value={state.pmids || ""}
            onChange={(e) => {
              setState((prev) => ({ ...prev, pmids: e.target.value }));
            }}
          />
        </Col>

        <Col span={12}>
          <div>
            <Typography.Text type="secondary">Authors</Typography.Text>
          </div>
          {/* <AutoComplete
            placeholder="Enter Authors"
            style={{
              width: "100%",
            }}
            value={state.authors}
            onChange={(value) => {
              setState((prev) => ({ ...prev, authors: value }));
            }}
            allowClear
          >
            {filterSuggest.data?.buckets?.authors
              .filter((item) =>
                state.authors
                  ? item.key.toLowerCase().includes(state.authors.toLowerCase())
                  : true
              )
              .map((item) => (
                <AutoComplete.Option key={item.key} value={item.key}>
                  {item.key}{" "}
                  <Badge count={item.doc_count} size="small" color="grey" />
                </AutoComplete.Option>
              ))}
          </AutoComplete> */}
          <Select
            mode="tags"
            placeholder="Enter Authors"
            style={{
              width: "100%",
            }}
            value={state.authors}
            onChange={(value) => {
              setState((prev) => ({ ...prev, authors: value }));
            }}
            onClick={
              (e) => e.stopPropagation()
            }
          >
            {filterSuggest.data?.buckets?.authors
              .map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}{" "}
                  <Badge count={item.doc_count} size="small" color="grey" />
                </Select.Option>
              ))}
          </Select>
        </Col>

        <Col span={12}>
          <div>
            <Typography.Text type="secondary">Tissue Type</Typography.Text>
          </div>
          {/* <AutoComplete
            placeholder="Select Tissue Type"
            style={{
              width: "100%",
            }}
            value={state["pipeline_outputs.primary_tissue_final"]}
            onChange={(value) => {
              setState((prev) => ({
                ...prev,
                "pipeline_outputs.primary_tissue_final": value,
              }));
            }}
            allowClear
          >
            {filterSuggest.data?.buckets?.[
              "pipeline_outputs.primary_tissue_final"
            ]
              ?.filter((item) =>
                state["pipeline_outputs.primary_tissue_final"]
                  ? item.key
                      .toLowerCase()
                      .includes(
                        state[
                          "pipeline_outputs.primary_tissue_final"
                        ].toLowerCase()
                      )
                  : true
              )
              .map((item) => (
                <AutoComplete.Option key={item.key} value={item.key}>
                  {item.key}{" "}
                  <Badge count={item.doc_count} size="small" color="grey" />
                </AutoComplete.Option>
              ))}
          </AutoComplete> */}
          <Select 
          mode="tags"
          placeholder="Select Tissue Type"
          style={{ width: "100%" }}
          allowClear
          value={state["pipeline_outputs.primary_tissue_final"]}
          onChange={(value) => {
            setState((prev) => ({
              ...prev,
              "pipeline_outputs.primary_tissue_final": value,
            }));
          }}
          onClick={
            (e) => e.stopPropagation()
          }
          >
            {filterSuggest.data?.buckets?.[
              "pipeline_outputs.primary_tissue_final"
            ]
              ?.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}{" "}
                  <Badge count={item.doc_count} size="small" color="grey" />
                </Select.Option>
              ))}

          </Select>


        </Col>
        <Col span={12}>
          <div>
            <Typography.Text type="secondary">Drug Name</Typography.Text>
          </div>
          {/* <AutoComplete
            placeholder="Select Tissue Type"
            style={{
              width: "100%",
            }}
            value={state["drug"]}
            onChange={(value) => {
              setState((prev) => ({
                ...prev,
                drug: value,
              }));
            }}
            allowClear
          >
            {filterSuggest.data?.buckets?.["drug"]
              ?.filter((item) =>
                state["drug"]
                  ? item.key.toLowerCase().includes(state["drug"].toLowerCase())
                  : true
              )
              .map((item) => (
                <AutoComplete.Option key={item.key} value={item.key}>
                  {item.key}{" "}
                  <Badge count={item.doc_count} size="small" color="grey" />
                </AutoComplete.Option>
              ))}
          </AutoComplete> */}
          <Select
            mode="tags"
            placeholder="Select Drug Name"
            style={{ width: "100%" }}
            allowClear
            value={state["drug"]}
            onChange={(value) => {
              setState((prev) => ({
                ...prev,
                drug: value,
              }));
            }}
            onClick={
              (e) => e.stopPropagation()
            }
          >
            {filterSuggest.data?.buckets?.["drug"]
              ?.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}{" "}
                  <Badge count={item.doc_count} size="small" color="grey" />
                </Select.Option>
              ))}
          </Select>
        </Col>
        <Col span={12}>
          <div>
            <Typography.Text type="secondary">Final Relevance</Typography.Text>
          </div>
          <Radio.Group
            defaultValue=""
            buttonStyle="solid"
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                final_relevance: e.target.value,
              }));
            }}
            value={state.final_relevance || ""}
          >
            <Radio.Button value="Include">Include</Radio.Button>
            <Radio.Button value="Exclude">Exclude</Radio.Button>
            <Radio.Button value="">All</Radio.Button>
          </Radio.Group>
        </Col>
        <Col span={12}>
          <div>
            <Typography.Text type="secondary">Drug Class</Typography.Text>
          </div>
          <Select
            mode="tags"
            placeholder="Select Drug Class"
            style={{ width: "100%" }}
            allowClear
            value={state["drug_classes"]}
            onChange={(value) => {
              setState((prev) => ({
                ...prev,
                drug_classes: value,
              }));
            }}
            onClick={
              (e) => e.stopPropagation()
            }
          >
            {filterSuggest.data?.buckets?.["drug_classes"]

              ?.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.key}{" "}
                  <Badge count={item.doc_count} size="small" color="grey" />
                </Select.Option>
              ))}
              </Select>

        </Col>
        <Col span={12}>
          <div>
            <Typography.Text type="secondary">Validated</Typography.Text>
          </div>
          <Radio.Group
            defaultValue=""
            buttonStyle="solid"
            onChange={(e) => {
              setState((prev) => ({ ...prev, is_validated: e.target.value }));
            }}
          >
            <Radio.Button value="yes">Yes</Radio.Button>
            <Radio.Button value="no">No</Radio.Button>
            <Radio.Button value="">All</Radio.Button>
          </Radio.Group>
        </Col>

        <Col span={12}>
          <div>
            <Typography.Text type="secondary">Is Included in Review</Typography.Text>
          </div>
          <Radio.Group
            defaultValue=""
            buttonStyle="solid"
            value={state.included_in_review == undefined ? null : state.included_in_review}
            onChange={(e) => {
              setState((prev) => ({ ...prev, included_in_review: e.target.value }));
            }}
          >
            <Radio.Button value={false}>False</Radio.Button>
            <Radio.Button value={true}>True</Radio.Button>
            <Radio.Button value={null}>All</Radio.Button>
          </Radio.Group>
        </Col>
      </Row>

      <Collapse
        ghost
        style={{
          marginTop: "1rem",
        }}
      >
        <Collapse.Panel header="GPT Filters" key="1">
          <Row gutter={[16, 16]}>
            <Col
              span={12}
              style={{
                marginBottom: "0.25rem",
              }}
            >
              <div>
                {" "}
                <Typography.Text type="secondary">
                  GPT Relevance
                </Typography.Text>
              </div>
              <Radio.Group
                defaultValue=""
                buttonStyle="solid"
                value={state["gpt_data.relevance"]}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    "gpt_data.relevance": e.target.value,
                  }));
                }}
              >
                <Radio.Button value="Include">Include</Radio.Button>
                <Radio.Button value="Exclude">Exclude</Radio.Button>
                <Radio.Button value="">All</Radio.Button>
              </Radio.Group>
            </Col>
            <Col
              span={12}
              style={{
                marginBottom: "0.25rem",
              }}
            >
              <div>
                {" "}
                <Typography.Text type="secondary">
                  GPT Study Type
                </Typography.Text>
              </div>
              <Select
              mode="tags"
                placeholder="Select Study Type"
                style={{ width: "100%" }}
                allowClear
                value={state["gpt_data.study_type"]}
                onChange={(value) => {
                  setState((prev) => ({
                    ...prev,
                    "gpt_data.study_type": value,
                  }));
                }}
              >
                {filterSuggest.data?.buckets?.["gpt_data.study_type"]?.map(
                  (item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.key}{" "}
                      <Badge count={item.doc_count} size="small" color="grey" />
                    </Select.Option>
                  )
                )}
              </Select>
            </Col>
            <Col
              span={24}
              style={{
                marginBottom: "0.25rem",
              }}
            >
              <div>
                {" "}
                <Typography.Text type="secondary">
                  GPT Therapeutic association
                </Typography.Text>
              </div>
              <Radio.Group
                defaultValue=""
                buttonStyle="solid"
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    "gpt_data.ta": e.target.value,
                  }));
                }}
                value={state["gpt_data.ta"]}
              >
                <Radio.Button value="Inconclusive">Inconclusive</Radio.Button>
                <Radio.Button value="Possible Benefit">
                  Possible Benefit
                </Radio.Button>
                <Radio.Button value="No Benefit">No Benefit</Radio.Button>
                <Radio.Button value="">All</Radio.Button>
              </Radio.Group>
            </Col>
            <Col
              span={24}
              style={{
                marginBottom: "0.25rem",
              }}
            >
              <div>
                {" "}
                <Typography.Text type="secondary">
                  GPT Endpoints
                </Typography.Text>
              </div>

              <Select
                mode="tags"
                placeholder="Enter Endpoints"
                style={{
                  width: "100%",
                }}
                value={state["gpt_data.endpoints"]}
                onChange={(value) => {
                  setState((prev) => ({
                    ...prev,
                    "gpt_data.endpoints": value,
                  }));
                }}
              >
                {filterSuggest.data?.buckets?.["gpt_data.endpoints"]?.map(
                  (item) => (
                    <Select.Option key={item.key} value={item.key}>
                      {item.key}{" "}
                      <Badge count={item.doc_count} size="small" color="grey" />
                    </Select.Option>
                  )
                )}
              </Select>
            </Col>
            <Col
              span={12}
              style={{
                marginBottom: "0.25rem",
              }}
            >
              <div>
                {" "}
                <Typography.Text type="secondary">
                  GPT Drug Combination
                </Typography.Text>
              </div>
              <Radio.Group
                defaultValue=""
                buttonStyle="solid"
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    "gpt_data.combination": e.target.value,
                  }));
                }}
                value={state["gpt_data.combination"]}
              >
                <Radio.Button value="combination">Combination</Radio.Button>
                <Radio.Button value="original">Alone</Radio.Button>

                <Radio.Button value="">All</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Collapse.Panel>
        <Collapse.Panel header="Pipeline Filters" key="2">
          <Row gutter={[16, 16]}>
            <Col
              span={12}
              style={{
                marginBottom: "0.25rem",
              }}
            >
              <div>
                {" "}
                <Typography.Text type="secondary">Relevance</Typography.Text>
              </div>
              <Radio.Group
                defaultValue=""
                buttonStyle="solid"
                value={state["pipeline_outputs.rel_predictions"]}
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    "pipeline_outputs.rel_predictions": e.target.value,
                  }));
                }}
              >
                <Radio.Button value="include">Include</Radio.Button>
                <Radio.Button value="exclude">Exclude</Radio.Button>
                <Radio.Button value="">All</Radio.Button>
              </Radio.Group>
            </Col>
            <Col
              span={12}
              style={{
                marginBottom: "0.25rem",
              }}
            >
              <div>
                {" "}
                <Typography.Text type="secondary">Study Type</Typography.Text>
              </div>
              <Select
                placeholder="Select Study Type"
                mode="tags"
                style={{ width: "100%" }}
                allowClear
                value={state["pipeline_outputs.predicted_stclass"]}
                onChange={(value) => {
                  setState((prev) => ({
                    ...prev,
                    "pipeline_outputs.predicted_stclass": value,
                  }));
                }}
              >
                {filterSuggest.data?.buckets?.[
                  "pipeline_outputs.predicted_stclass"
                ]?.map((item) => (
                  <Select.Option key={item.key} value={item.key}>
                    {item.key}{" "}
                    <Badge count={item.doc_count} size="small" color="grey" />
                  </Select.Option>
                ))}
              </Select>
            </Col>
            {/* <Col
              span={12}
              style={{
                marginBottom: "0.25rem",
              }}
            >
              <div>
                {" "}
                <Typography.Text type="secondary">Tissue Type</Typography.Text>
              </div>
              <Select
                placeholder="Select Tissue Type"
                style={{ width: "100%" }}
                allowClear
                value={state["pipeline_outputs.primary_tissue_final"]}
                onChange={(value) => {
                  setState((prev) => ({
                    ...prev,
                    "pipeline_outputs.primary_tissue_final": value,
                  }));
                }}
                mode="tags"
              >
                {filterSuggest.data?.buckets?.[
                  "pipeline_outputs.primary_tissue_final"
                ]?.map((item) => (
                  <Select.Option key={item.key} value={item.key}>
                    {item.key}{" "}
                    <Badge count={item.doc_count} size="small" color="grey" />
                  </Select.Option>
                ))}
              </Select>
            </Col> */}

            <Col
              span={24}
              style={{
                marginBottom: "0.25rem",
              }}
            >
              <div>
                {" "}
                <Typography.Text type="secondary">
                  Therapeutic association
                </Typography.Text>
              </div>
              <Radio.Group
                defaultValue=""
                buttonStyle="solid"
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    "pipeline_outputs.predicted_taclass": e.target.value,
                  }));
                }}
                value={state["pipeline_outputs.predicted_taclass"] || ""}
              >
                <Radio.Button value="possible benefit">
                  Possible Benefit
                </Radio.Button>
                <Radio.Button value="no benefit">No Benefit</Radio.Button>
                <Radio.Button value="">All</Radio.Button>
              </Radio.Group>
            </Col>
            <Col
              span={12}
              style={{
                marginBottom: "0.25rem",
              }}
            >
              <div>
                {" "}
                <Typography.Text type="secondary">
                  Drug Combination
                </Typography.Text>
              </div>
              <Radio.Group
                defaultValue=""
                buttonStyle="solid"
                onChange={(e) => {
                  setState((prev) => ({
                    ...prev,
                    "pipeline_outputs.combination_or_alone_option2_abstract":
                      e.target.value,
                  }));
                }}
                value={
                  state[
                    "pipeline_outputs.combination_or_alone_option2_abstract"
                  ] || ""
                }
              >
                <Radio.Button value="Alone">Alone</Radio.Button>
                <Radio.Button value="Combination">Combination</Radio.Button>
                <Radio.Button value="">All</Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
        </Collapse.Panel>
      </Collapse>
      <Row
        style={{
          marginTop: "1rem",
        }}
        gutter={8}
      >
        <Col>
          <Button
            onClick={() => {
              onChange(state);
            }}
            type="primary"
          >
            Apply
          </Button>
        </Col>
        <Col>
          <Button
            onClick={() => {
              setState({});
              onChange({});
            }}
          >
            Clear{" "}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
const FilterViewV2 = ({ onChange, onDisplayChange, currentDisplay }) => {
  const [state, setState] = useFilterState({
    otherFilters: {},
    search: "",
    pmids: "",
  });

  useEffect(() => {
    // Parse search using parseSearch
    let parseQuery = {}
    if (state.search) {
      const search = state.search;
      parseQuery = parsePubMedQuery(search);
      console.log("Parsed Search", parseQuery);
    }
    onChange({
      otherFilters: state.otherFilters,
      search: state.search,
      pmids: state.pmids,
      parsedQuery: parseQuery,
      
    });
  }, [state]);

  return (
    <>
      <Row style={{ width: "90%" }} gutter={16}>
        <Col span={18}>
          <Input.Search
            defaultValue={state.search}
            onSearch={(e) => {
              setState((prev) => ({ ...prev, search: e }));
            }}
            allowClear
            placeholder="Search for articles"
          ></Input.Search>
        </Col>
        <Col span={6}>
          <Popover
            placement="bottomRight"
            trigger="click"
            content={
              <div
                style={{
                  //   width: "75%",
                  //   borderRadius: "20px",
                  //   border: "1px solid #d9d9d9",
                  //   margin: "0.5rem 0rem",
                  //   padding: "1rem",
                  width: "600px",
                }}
              >
                <div>
                
                  <AdvancedFilters
                    onChange={(value) => {
                      console.log("Advanced Filters", value);
                      setState((prev) => ({ ...prev, otherFilters: value }));
                    }}
                    value={state.otherFilters}
                  />
                </div>
             
              </div>
            }
          >
            <Badge count={Object.keys(state.otherFilters).length} size="small">
              <Button>
                {" "}
                <FilterOutlined /> Filter
              </Button>
            </Badge>
          </Popover>

          <Radio.Group
          value={currentDisplay || "split"}
            defaultValue="split"
            buttonStyle="solid"
            style={{
              marginLeft: "1rem",
            }}
            onChange={(e) => onDisplayChange(e.target.value)}
          >
            <Radio.Button value="split">
              <i class="fa-solid fa-table-columns"></i>
            </Radio.Button>
            <Radio.Button value="list">
              <i class="fa-solid fa-list"></i>
            </Radio.Button>
            <Radio.Button value="table">
              <i class="fa-solid fa-table"></i>
            </Radio.Button>
          </Radio.Group>
        </Col>
      </Row>
    </>
  );
};
export default FilterViewV2;
