import logo from "./logo.svg";
import React from "react";

import "./App.css";
import {
  Row,
  Col,
  Button,
  Card,
  Tag,
  Typography,
  Layout,
  Menu,
  Anchor,
  ConfigProvider,
} from "antd";
import { useEffect, useRef, useState } from "react";
import ReactMarkdown from "react-markdown";
import { QueryClient, QueryClientProvider } from "react-query";

import axios from "axios";
import remarkGfm from "remark-gfm";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
  useLocation,
  useNavigate,
} from "react-router-dom";

import ResponseForm from "./pages/responseForm";
import createPersistedState from "use-persisted-state";
import Predictions from "./predictions";
import Auth from "./services/auth";
import LoginSignupPage from "./pages/signInUp";
import LabelHome from "./pages/label";
import LabelTaskPage from "./pages/labelTask";
import { PubmedDataItems } from "./services/dataService";
import PubmedDataHome from "./pages/pubmedData";
import PubmedDataSplit from "./pages/pubmedDataSplit";
import { ProviderAuth, useAuth } from "./authContext";
import LLMExperiments from "./predictions";
import BookmarkPage from "./pages/bookmarks";
import PubmedDataHomeV2 from "./pages/pubmedDataV2";
import ValidationView from "./pages/validation";
import ValidationSets from "./pages/validationSets";
import PubmedDataDetail from "./pages/pubmedDataDetail";
import PubmedSearch from "./pages/pubmedSearch";
import AllSummary from "./pages/gptSummary/allSummary";
import Scoring from "./pages/scoring";
// import { Header } from "antd/es/layout/layout";
const labelAppState = createPersistedState("labelAppState");
const globalAppState = createPersistedState("globalAppState");

const protectedRouter = createBrowserRouter([
  {
    path: "/llm",
    element: (
      <div>
        <LLMExperiments />
      </div>
    ),
  },
  {
    path: "/label/:id",
    element: <LabelHome />,
  },
  {
    path: "/label",
    element: <LabelTaskPage />,
  },
  {
    path: "/pubmed-search",
    element: <PubmedSearch />,
  },

  {
    path: "/validation",
    element: <ValidationSets />,
  },
  {
    path: "/validation/:id",
    element: <ValidationView />,
  },

  // {
  //   path: "/dataset/:id",
  //   element: <PubmedDataHome />,
  // },
  {
    path: "/dataset",
    element: <PubmedDataHomeV2 />,
  },
  {
    "path": "/scoring",
    "element": <Scoring />
  },
  {
    path: "/dataset/:id",
    element: <PubmedDataDetail />,
  },
  // {
  //   path: "/dataset/v2",
  //   element: <PubmedDataHomeV2 />,
  // },
  {
    path: "/bookmarks",
    element: <BookmarkPage />,
  },
  {
    path: "/gpt-summary",
    element: <AllSummary />,
  },

  {
    path: "/*",
    element: <Navigate to="/dataset" />,
  },
]);

const baseRouter = createBrowserRouter([
  {
    path: "/login",
    element: (
      <div>
        <LoginSignupPage />
      </div>
    ),
  },
  {
    path: "/*",
    element: (
      <div>
        <Navigate to="/login" />
      </div>
    ),
  },
]);

const queryClient = new QueryClient();

const App = () => {
  // const auth = new Auth();
  const auth = useAuth();
  // const location = useLocation();
  // const navigate = useNavigate();
  const [state, setState] = useState({});
  // console.log(location)
  console.log("Auth", auth);
  return (
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            token: {
              // fontFamily: "Nunito Sans",
              fontFamily: "IBM Plex Sans",
              borderRadius: "8px",
            },
          }}
        >
          {auth.loading && "Loading..."}
          {!auth.loading ? (
            auth.isAuthenticated ? (
              <>
                <RouterProvider router={protectedRouter} />
              </>
            ) : (
              <RouterProvider router={baseRouter} />
            )
          ) : null}
        </ConfigProvider>
      </QueryClientProvider>
    </React.StrictMode>
  );
};

const FinalApp = () => {
  return (
    <React.StrictMode>
      <ProviderAuth>
        <App />
      </ProviderAuth>
    </React.StrictMode>
  );
};
export default FinalApp;
